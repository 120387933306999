<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_package()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Dodaj kategorie pakietu</v-card-title>
          <v-card-text>

            <v-form
              ref="form" 
              v-model="valid"
              lazy-validation
            >

              <v-text-field
                v-model="package_data.name"
                label="Tytuł kategorii pakietu"
                color="primary"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

            </v-form>

            <h2 class="inner_heading">Kolor wyróżniający</h2>

            <v-color-picker
              v-model="package_data.color"
              class="ma-2"
              :swatches="swatches"
              show-swatches
              mode="hexa"
            ></v-color-picker>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista kategorii pakietów',
        disabled: false,
        to: '/pakiety-kategorie',
      },
      {
        text: 'Edycja kategorii pakietu',
        disabled: true,
        to: 'edytuj',
      },
    ],

    package_data: {
      name: '',
      color: ''
    },

    swatches: [
      ['#FF0000', '#AA0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#0000FF', '#0000AA', '#000055'],
    ],
  }),
  methods: {
    add_package(){
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.package_data.name);
        formData.append("color", this.package_data.color);

        this.$axios({url: this.$store.state.api +'/category_package', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            // console.log(resp.data);

            this.$router.push('/pakiety-kategorie');
            this.$store.commit("snackbar", {
              text: "Dodano kategorie pakietu",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
</style>